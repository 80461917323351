import { Component } from "@angular/core";
import { ApiRestService, Divida } from "../api-rest.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-opcoes-routlet",
  templateUrl: "./opcoes-routlet.component.html",
  styleUrls: ["./opcoes-routlet.component.css"],
})
export class OpcoesRoutletComponent {
  constructor(
    public apiRestService: ApiRestService,
    private toastr: ToastrService
  ) {}

  public nome: string = this.apiRestService.getNome();
  public numeroContrato: string = this.apiRestService.numeroContrato;
  public dataPagamento: string = this.apiRestService.dataPagamento;
  public totalDivida: string = this.apiRestService.totalDivida;
  public telMask = [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public smsCheck: boolean;
  public emailCheck: boolean;
  public telefone: string;
  public email: string;

  showOpcoes: boolean = true;
  showNegocieOnline: boolean;
  showRecebaNossaLigacao: boolean;
  showAcordosAndamento: boolean;
  showFizPagamento: boolean;

  negocieOnline() {
    this.showOpcoes = false;
    this.showNegocieOnline = true;
  }

  recebaNossaLigacao() {
    this.showOpcoes = false;
    this.showRecebaNossaLigacao = true;
  }

  escolherData() {
    this.apiRestService.telaFinal = true;
    this.showNegocieOnline = false;
  }

  showOpcoesParcelamento() {}

  hideOpcoesParcelamento() {}

  segundaVia() {
    if (this.emailCheck) this.salvarTelefone();
    if (this.smsCheck) this.salvarEmail();
    this.apiRestService.abrirBoleto(this.smsCheck, this.emailCheck);
  }

  salvarEmail() {
    if (!this.apiRestService.validarESalvarEmail(this.email))
      this.toastr.error("E-mail inválido");
    return;
  }

  salvarTelefone() {
    if (!this.apiRestService.validarESalvarTelefone(this.telefone))
      this.toastr.error("Número de telefone inválido");
    return;
  }

  fizPagamento() {
    this.showOpcoes = false;
    this.showFizPagamento = true;
  }
}
