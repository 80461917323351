import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {ClipboardService} from 'ngx-clipboard';
import bwipjs from 'bwip-angular2';


@Component({
  selector: 'app-boleto',
  templateUrl: './boleto.component.html',
  styleUrls: ['./boleto.component.css']
})
export class BoletoComponent implements OnInit {

  @ViewChild('template', { static: false }) templateRef: TemplateRef<any>;

  public linha: string;
  public data: string;
  public valor: string;
  public cliente: string;
  public contrato: string;
  public codigo: string;

  constructor(route: ActivatedRoute,private clipBoard: ClipboardService) {

    let boletoDados = JSON.parse(localStorage.getItem('boletoDados'));
    // let boletoDados = {
    //   'linha': "34191.79001 01043.510047 91020.150008 3 89060026000",
    //   'valor': "123",
    //   'data': "123",
    //   'cliente': "123",
    //   'contrato': "123",
    //   'codigo': "34191790010104351004791020150008389060026000",
    // }
    
    this.linha = boletoDados.linha || "";
    this.valor = boletoDados.valor || "";
    this.data = boletoDados.data || "";
    this.cliente = boletoDados.cliente || "";
    this.contrato = boletoDados.contrato || "";
    this.codigo = boletoDados.codigo || "";

  }

  public newDate = new Date();
  // Necessario para corrigir bug no Edge:
  public hoje = (this.newDate.toLocaleString("pt-BR").indexOf(',') > -1) ? this.newDate.toLocaleString("pt-BR").slice(0, 10) : this.newDate.toLocaleString("pt-BR").split(' ')[0];

  ngOnInit() {
    setTimeout(this.mostrarBoleto, 1000, this.codigo)  // necessario carregar o codigo de barras depois do resto da pagina 

  }

  copiarLinhaDigitavel() {
    this.clipBoard.copyFromContent(this.codigo);
  }

  mostrarBoleto(codigo: string) {

    bwipjs('barcodeCanvas', {
      bcid: 'interleaved2of5',
      text: codigo,
      scale: 1,               // 3x scaling factor
      height: 20,             // Bar height, in millimeters
      includetext: true,      // Show human-readable text
      textxalign: 'center',   // Always good to set this
    }, (err, cvs) => {
      if (err) {
      } else {
      }
    });
  }

}
