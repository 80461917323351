import {
  Component, EventEmitter, Output, OnInit,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiRestService, Boleto } from '../api-rest.service';

@Component({
  selector: 'app-prazo-finalizacao',
  templateUrl: './prazo-finalizacao.component.html',
  styleUrls: ['./prazo-finalizacao.component.css'],
})

export class PrazoFinalizacaoComponent implements OnInit {
  @Output() clickVoltar = new EventEmitter<boolean>();

  public fim = true;

  public sucesso: boolean;

  public sucessoMsg: string;

  public erro: boolean;

  public loadingBoleto: boolean;

  public erroBoleto: boolean;

  public boleto: Boleto;

  public porSms: boolean;

  public porEmail: boolean;

  public smsRes = '';

  public erroMsg = '';

  public dadosPagamento;

  public telMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public smsCheck: boolean;

  public emailCheck: boolean;

  public telefone: string;

  public email: string;

  constructor(
    public apiRestService: ApiRestService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.dadosPagamento = this.apiRestService.dadosPagamento;
    if (this.apiRestService.segundaVia) {
      this.fim = false;

      this.apiRestService.getBoletoAcordo(this.apiRestService.dataPagamento).subscribe((res) => {
        if (res.data && res.data.result && res.data.result.DADOBOLETO) {
          this.sucesso = true;
          this.sucessoMsg = 'Pegue aqui sua segunda via!';
          this.boleto = res;
          this.apiRestService.dataPagamento = this.boleto.data.result.DADOBOLETO.DATVENCI;
        } else if (res.data.result.RETORNOTEXTO.includes('DATA DE PAGAMENTO ANTERIOR A HOJE')) this.erroMsg = 'Seu boleto já venceu (W).';
        else this.erroMsg = 'Houve um erro. Tente novamente, por favor.';
      });
    }
  }

  voltarEmail() {
    this.porEmail = false;
    this.sucesso = true;
  }

  voltarSms() {
    this.porSms = false;
    this.sucesso = true;
    this.smsRes = '';
  }

  mudarData() {
    this.clickVoltar.emit(true);
  }

  ehAVista() {
    return this.apiRestService.quantidadeParcelas === 1;
  }

  vezesPar() {
    return this.apiRestService.quantidadeParcelas;
  }

  dataPrimeira() {
    const nums = this.apiRestService.dataPagamento.split('-');
    return `${nums[2]}/${nums[1]}/${nums[0]}`;
  }

  valorParcela() {
    return this.apiRestService.valorParcela;
  }

  parTotal() {
    if (!this.ehAVista()) return `${this.valorParcela()} + ${this.vezesPar() - 1} X ${this.valorParcela()}`;
    return this.valorParcela();
  }

  gravaAcordo() {
    this.erro = false;
    this.fim = false;

    if (this.smsCheck) this.salvarTelefone();
    if (this.emailCheck) this.salvarEmail();

    this.apiRestService.gravaAcordo().subscribe((res: any) => {
      this.apiRestService.loading = false;
      if (res.data.RETORNO.CODIGO === '00') {
        this.apiRestService.novoContrato = res.data.RETORNO.CODPARCE;
        this.sucesso = true;
        this.toastr.success('Negociação realizada com sucesso.');
        this.apiRestService.abrirBoleto(this.smsCheck, this.emailCheck);
      } else {
        this.toastr.error(res.data.RETORNO.MENSAGEM);
      }
    });
  }

  salvarEmail() {
    if (!this.apiRestService.validarESalvarEmail(this.email)) this.toastr.error('E-mail inválido');
  }

  salvarTelefone() {
    if (!this.apiRestService.validarESalvarTelefone(this.telefone)) this.toastr.error('Número de telefone inválido');
  }

  valorTotalParcelado() {
    return this.apiRestService.totalParcelado;
  }
}
