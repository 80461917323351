import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiRestService } from '../api-rest.service';

@Component({
  selector: 'app-cpf-cnpj-box',
  templateUrl: './cpf-cnpj-box.component.html',
  styleUrls: ['./cpf-cnpj-box.component.css'],
})
export class CpfCnpjBoxComponent {
  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private apiRestService: ApiRestService,
    private toastr: ToastrService,
  ) {
  }

  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  public cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  public cpf_cnpj: string;

  public verificando = false;

  public whatsapp = { telefone: this.apiRestService.phone, mensagem: this.apiRestService.text };

  backspacePress(event: any) {
    if (event.target.value.substr(event.target.value.length - 1) === '.'
      || event.target.value.substr(event.target.value.length - 1) === '/'
      || event.target.value.substr(event.target.value.length - 1) === '-') {
      this.cd.detectChanges();
      event.preventDefault();
      this.cpf_cnpj = this.cpf_cnpj.slice(0, -1);
    }
  }

  mask() {
    return {
      mask: (value : string) => {
        if (value.length <= 14) return this.cpfMask;
        return this.cnpjMask;
      },
      guide: false,
    };
  }

  valida() {
    if (typeof this.cpf_cnpj === 'undefined') {
      this.toastr.error('Por favor, preencha o campo CPF / CNPJ para acessar o sistema.');
      return;
    }
    const cpfCnpj = this.cpf_cnpj.replace(/[`\-.\{\}\[\]\\\/]/gi, '');

    switch (cpfCnpj.length) {
      case 14:
        if (!this.testaCNPJ(cpfCnpj)) {
          this.toastr.error('CNPJ inválido.');
          break;
        }
        this.processarCpfCnpj(cpfCnpj);
        break;
      case 11:
        if (!this.testaCPF(cpfCnpj)) {
          this.toastr.error('CPF inválido.');
          break;
        }
        this.processarCpfCnpj(cpfCnpj);
        break;
      default: {
      }
    }
  }

  processarCpfCnpj(cpfCnpj) {
    this.verificando = true;
    this.apiRestService.temDividasouAcordo(cpfCnpj).subscribe((res : any) => {
      this.verificando = false;
      console.log(res);
      switch (res) {
        case 1: {
          this.router.navigateByUrl('/negocie-online');
          break;
        }
        case 0: {
          this.toastr.success('Parabêns, você não possui débitos conosco!');
          break;
        }
        case 2: {
          this.toastr.error('Houve um erro. Tente novamente, por favor.');
          break;
        }
        case 3: {
          this.toastr.error('Desculpa, a dívida não pode ser localizada neste site.');
          break;
        }
        case 4: {
          this.apiRestService.temAcordo(cpfCnpj).subscribe((res: any) => {
            if (!res.response) {
              this.toastr.error('Cliente já possui um parcelamento ativo.');
            } else {
              const dataPagamento = new Date(res.data.dtpgmto);
              const hoje = new Date();
              if(dataPagamento.getTime() < hoje.getTime()){
                this.toastr.error('Cliente já possui um parcelamento ativo.');
              }else{
                this.apiRestService.novoContrato = res.data.codparce;
                this.apiRestService.dataPagamento = res.data.dtpgmto;
                this.apiRestService.segundaVia = true;
                this.router.navigateByUrl('/opcoes-routlet');
              }
            }
          });
        }
      }
    });
  }

  testaCPF(cpf: string): boolean {
    let soma:number;
    let resto:number;
    let i:number;

    soma = 0;
    if (cpf === '00000000000') return false;

    for (i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10), 10)) return false;

    soma = 0;
    for (i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11), 10)) return false;
    return true;
  }

  testaCNPJ(cnpj: string): boolean {
    if (cnpj.length !== 14) return false;

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) return false;

    // Cáculo de validação
    const t = cnpj.length - 2;
    const d = cnpj.substring(t);
    const d1 = parseInt(d.charAt(0), 10);
    const d2 = parseInt(d.charAt(1), 10);
    const calc = (x) => {
      const n = cnpj.substring(0, x);
      let y = x - 7;
      let s = 0;
      let r = 0;

      for (let i = x; i >= 1; i -= 1) {
        s += +n.charAt(x - i) * (y--);
        if (y < 2) y = 9;
      }

      r = 11 - s % 11;
      return r > 9 ? 0 : r;
    };

    return calc(t) === d1 && calc(t + 1) === d2;
  }
}
