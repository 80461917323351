import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, flatMap, map, retry, shareReplay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiRestService {
  public rootPath = "/"; // atualmente esta hospedado em http://negociasp.com.br/
  public retry = 15; // tentativas consecutivas de conectar com um endpoint

  public PARC = [];
  public PRAZOS = [];
  public NUMPARC: number;
  public PRAZOPAGTO: number;

  public dadosPagamento = {};

  public msgErro = "Houve um erro. Tente novamente, por favor.";

  public segundaVia: boolean;

  public linkTelaFim: boolean;
  public prest = Array<Prest>();

  public devedor: Devedor;
  public divida: Divida;
  public cpfCnpj: string;
  public telefone: string;
  public email: string;
  public telaFinal = false;

  public quantidadeParcelas: number;
  public dataPagamento: string;
  public valorParcela: string;
  public novoContrato: string;
  public descontoEscolhido: string;
  public descontoAVista = [];
  public descontos = [];
  public totalDivida: string;
  public totalDividaBr: string;
  public totalParcelado: string;
  public boleto: Boleto;
  public loading = false;
  public enviandoSms = false;
  public enviandoEmail = false;
  public numeroContrato: string;
  public phone = "5511958953739";
  public text = "Olá";

  private urlBase = "https://semparar.midiahub.com.br/api/w-api/semparar";

  public urlDadosDevedor = this.urlBase + "/dadosDevedor";
  private urlDadosDivida = this.urlBase + "/consultarDivida";
  private urlOpcoesPagamento = this.urlBase + "/calcularParcelamento";
  private urlGravaAcordo = this.urlBase + "/confirmarParcelamento";
  private urlBoletoAcordo = this.urlBase + "/emitirBoleto";
  private urlEnviaSms = this.urlBase + "/enviarSMS";
  private urlBoletoEmail = this.urlBase + "/enviarBoletoPorEmail";
  private urlTemAcordo = this.urlBase + "/consultarNegociacao";
  private urlTemDesconto =
    "https://semparar.midiahub.com.br/api/landingpagesemparar/apiresposta/apirequest_getparcelamento.php";

  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  temDividasouAcordo(cpfCnpj: string): Observable<number> {
    this.cpfCnpj = cpfCnpj;
    return this.getDadosDevedor(cpfCnpj).pipe(
      flatMap((devedor: Devedor) => {
        if (devedor.data.status) {
          if (devedor.data.status.condigo === "1") {
            this.devedor = devedor;
            return this.getDadosDivida(
              devedor.data.cliente[0].Regis,
              devedor.data.cliente[0].Contrato
            ).pipe(
              flatMap((divida: any) => {
                if (divida.data.DIVIDA) {
                  if (divida.data.DIVIDA.PREST.length) {
                    this.divida = divida;
                    const parcelasVal = divida.data.DIVIDA.PREST.map((obj) =>
                      parseFloat(obj.VALPRINC.replace(",", "."))
                    );
                    this.totalDivida = parcelasVal
                      .reduce((a, c) => a + c)
                      .toFixed(2);
                    this.totalDividaBr = this.totalDivida.replace(
                      /./g,
                      (c, i) => (i == this.totalDivida.length - 3 ? "," : c)
                    );
                  } else {
                    this.totalDivida = divida.data.DIVIDA.PREST.VALPRINC;
                    this.divida = {
                      data: {
                        DIVIDA: {
                          PREST: [
                            {
                              PRODUTO: divida.data.DIVIDA.PREST.PRODUTO,
                              VALPRINC: divida.data.DIVIDA.PREST.VALPRINC,
                              VENCIMENTO: divida.data.DIVIDA.PREST.VENCIMENTO,
                              CONTRATO: divida.data.DIVIDA.PREST.CONTRATO,
                              NUMPREST: divida.data.DIVIDA.PREST.NUMPREST,
                            },
                          ],
                        },
                      },
                    };
                  }

                  const da = Math.min.apply(
                    Math,
                    this.divida.data.DIVIDA.PREST.map((pres) => {
                      const d = new Date(pres.VENCIMENTO);
                      d.setDate(d.getDate() + 1);
                      return d.getTime();
                    })
                  );
                  const hoje = new Date();
                  const hojeSec = hoje.getTime();
                  const dias = Math.floor((hojeSec - da) / 86400000) + 1;

                  return this.temDesconto(cpfCnpj, dias.toString()).pipe(
                    map((des) => {
                      this.descontos = des;

                      this.descontoAVista = this.descontos.filter((de) => {
                        return de.qtde_parcelas === "1";
                      });

                      this.descontoAVista.sort(
                        (a, b) => a.qtde_dias - b.qtde_dias
                      );

                      this.PRAZOPAGTO = Math.max.apply(
                        Math,
                        this.descontos.map(function (o) {
                          return o.qtde_dias;
                        })
                      );

                      this.PRAZOS = [
                        ...new Set(
                          this.descontos.map((item) => item.qtde_dias)
                        ),
                      ];
                      this.PARC = [
                        ...new Set(
                          this.descontos.map((item) => item.qtde_parcelas)
                        ),
                      ];
                      this.NUMPARC = this.PARC.length;
                      return 1;
                    })
                  );
                } else if (
                  divida.data.RETORNOTEXTO &&
                  divida.data.RETORNOTEXTO.includes(
                    "Cliente não está na assessoria"
                  )
                ) {
                  return of(3);
                } else if (
                  divida.data.RETORNOTEXTO &&
                  divida.data.RETORNOTEXTO.includes(
                    "CLIENTE POSSUI PARCELAMENTO ATIVO"
                  )
                ) {
                  return of(4);
                } else return of(2);
              })
            );
          }
        } else if (devedor.data.condigo === "25") {
          return of(0);
        } else return of(2);
      })
    );
  }

  getNome(): string {
    return this.devedor.data.cliente[0].Nome.toLowerCase();
  }

  getNomeSMS() {
    return this.devedor.data.cliente[0].Nome.split(" ")[0];
  }

  meLigue(num: string): Observable<boolean> {
    return of(true);
  }

  fizPagamento(): Observable<boolean> {
    return of(true);
  }

  getDadosDevedor(cpfCnpj: string): Observable<Devedor> {
    const cpfCnpjParam = new HttpParams()
      .set("cpf", cpfCnpj)
      .set("origin", "portal");
    return this.http
      .post<Devedor>(this.urlDadosDevedor, cpfCnpjParam, this.httpOptions)
      .pipe(
        retry(this.retry),
        catchError(() => {
          return EMPTY;
        }),
        shareReplay()
      );
  }

  getDadosDivida(regis: string, contrato: string): Observable<Divida> {
    (<any>window).CPF_API = this.urlBase;
    (<any>window).CPF_LOGGED = this.cpfCnpj;

    this.numeroContrato = contrato;

    /**
     * Step 1 = Consulta CPF
     * Step 2 = Consulta dividas
     * Step 3 = Realiza acordo
     * Step 4 = Visualiza boleto ou envia boleto por email
     * Modelo Objeto: {S1:"buscaCPF",S2:"buscaDividas",S3:"gravaAcordo",S4:"view-p1,view-p2,email-2,email-p2"};
     */
    (<any>window).CPF_TRACE = { S1: "buscaCPF", S2: "", S3: "", S4: [] };

    const cpfDevedorParam = new HttpParams()
      .set("regis", regis)
      .set("contrato", contrato)
      .set("cpf", this.cpfCnpj)
      .set("origin", "portal");

    return this.http
      .post<Divida>(this.urlDadosDivida, cpfDevedorParam, this.httpOptions)
      .pipe(
        retry(this.retry),
        catchError(() => {
          return EMPTY;
        }),
        shareReplay()
      );
  }

  getOpcoesPagamento(quantidadeParcelas, desconto): Observable<any> {
    (<any>window).CPF_TRACE.S2 = "buscaDividas";

    const cpfCnpjParam = new HttpParams()
      .set("dtpagto", this.dataPagamento)
      .set("cpf", this.cpfCnpj)
      .set("pctdesconto", desconto.slice(0, -1))
      .set("valdesconto", "0")
      .set("credor", "1")
      .set("regis", this.devedor.data.cliente[0].Regis)
      .set("descdespesa", "N")
      .set("deschonorarios", "N")
      .set("valoradicional", "0")
      .set("produt", "SEMPARAR")
      .set("operesp", "")
      .set("contr", this.divida.data.DIVIDA.PREST[0].CONTRATO)
      .set("vencto", this.divida.data.DIVIDA.PREST[0].VENCIMENTO)
      .set("numprest", JSON.stringify(this.prest))
      .set("produto", "SEMPARAR")
      .set("contrato", this.divida.data.DIVIDA.PREST[0].CONTRATO)
      .set("dtentrada", this.dataPagamento)
      .set("qtdparc", String(quantidadeParcelas - 1))
      .set("valentrada", "0")
      .set("descpercentual", "S")
      .set("desconto", desconto.slice(0, -1))
      .set("renegociacao", "N")
      .set("propostaacordo", "N")
      .set("datasmensais", "S")
      .set("parcelasiguais", "S")
      .set("todasnrc", "N")
      .set("tipoenviobol", "")
      .set("formapagtoentrada", "B")
      .set("origin", "portal");
    return this.http
      .post(this.urlOpcoesPagamento, cpfCnpjParam, this.httpOptions)
      .pipe(
        retry(this.retry),
        catchError(() => {
          return EMPTY;
        }),
        shareReplay()
      );
  }

  getBoletoAcordo(dtpagto = this.dataPagamento): Observable<any> {
    (<any>window).CPF_DEBTOR_COD = this.devedor.data.cliente[0].Regis;
    (<any>window).CPF_PARCEL_COD = this.novoContrato;

    const params = new HttpParams()
      .set("dtpagto", dtpagto)
      .set("cpf", this.cpfCnpj)
      .set("pctdesconto", "0")
      .set("valdesconto", "0")
      .set("credor", "1")
      .set("regis", this.devedor.data.cliente[0].Regis)
      .set("descdespesa", "N")
      .set("deschonorarios", "N")
      .set("valoradicional", "0.00")
      .set("operesp", "")
      .set(
        "numprest",
        JSON.stringify([
          { produt: "PARC", contr: this.novoContrato, vencto: dtpagto },
        ])
      )
      .set("origin", "portal");

    return this.http.post(this.urlBoletoAcordo, params, this.httpOptions).pipe(
      retry(this.retry),
      catchError(() => {
        return EMPTY;
      }),
      shareReplay()
    );
  }

  abrirBoleto(enviarBoletoSMS: boolean, enviarBoletoEmail: boolean) {
    this.loading = true;
    this.getBoletoAcordo().subscribe((boleto) => {
      this.boleto = boleto;
      if (boleto.data.result.DADOBOLETO) {
        this.loading = false;

        const data =
          boleto.data.result.DADOBOLETO.DATVENCI.substring(8, 10) +
          "/" +
          boleto.data.result.DADOBOLETO.DATVENCI.substring(5, 7) +
          "/" +
          boleto.data.result.DADOBOLETO.DATVENCI.substring(0, 4);

        const boletoDados = {
          data: data,
          linha: boleto.data.result.DADOBOLETO.LINHADIGITAVEL,
          valor: this.doisDigitosDecimais(
            boleto.data.result.DADOBOLETO.VALPAGO
          ),
          cliente: boleto.data.result.DADOBOLETO.NOMCLIEN,
          contrato: boleto.data.result.DADOBOLETO.INSTRUCAO,
          codigo: boleto.data.result.DADOBOLETO.CODIGOBARRA,
        };

        if (enviarBoletoSMS) {
          this.enviandoSms = true;
          this.enviaBoletoSms(
            this.boleto.data.result.DADOBOLETO.LINHADIGITAVEL,
            this.boleto.data.result.DADOBOLETO.CODIGOBARRA,
            data,
            this.doisDigitosDecimais(
              this.boleto.data.result.DADOBOLETO.VALPAGO
            ),
            1
          ).subscribe((res) => {
            if (res.message) this.toastr.success(res.message);
            else this.toastr.error(res.msgErro);
            this.enviandoSms = false;
          });
        }

        if (enviarBoletoEmail) {
          this.enviandoEmail = true;

          this.enviaBoletoEmail(
            this.boleto.data.result.DADOBOLETO.INSTRUCAO,
            this.boleto.data.result.DADOBOLETO.VALPAGO,
            data,
            this.boleto.data.result.DADOBOLETO.LINHADIGITAVEL,
            this.boleto.data.result.DADOBOLETO.CODIGOBARRA,
            1
          ).subscribe((res) => {
            if (res.message) this.toastr.success(res.message);
            else this.toastr.error(res.msgErro);
            this.enviandoEmail = false;
          });
        }

        localStorage.setItem("boletoDados", JSON.stringify(boletoDados));
        window.open("/boleto", "_blank");
      } else
        this.toastr.error(
          "Desculpe, não foi possível emitir seu boleto no momento."
        );
    });
  }

  enviaBoletoSms(
    linha: string,
    codigo: string,
    vencimento: string,
    valor: string,
    parcela_num: number
  ): Observable<any> {
    const devedor_cod = (<any>window).CPF_DEBTOR_COD;
    const parcela_cod = (<any>window).CPF_PARCEL_COD;

    const set_parcela = "s" + parcela_num;

    if (Object.values((<any>window).CPF_TRACE.S4).indexOf(set_parcela) <= -1) {
      (<any>window).CPF_TRACE.S4.push(set_parcela);
    }

    const nome = this.getNomeSMS();

    linha = linha.trim();
    linha = linha.replace(/\s\s\s/g, " ");
    linha = linha.replace(/\s\s/g, " ");

    const params = new HttpParams()
      .set("cpf", this.cpfCnpj)
      .set("numeroenvio", this.telefone)
      .set(
        "textosms",
        nome +
          ", Codigo de barras da sua conta Sem Parar com vencimento " +
          vencimento +
          ", Valor R$ " +
          valor +
          ", Codigo " +
          linha
      )
      .set("vencimento", vencimento)
      .set("valor", valor)
      .set("codigobarra", codigo)
      .set("linhadigitavel", linha)
      .set("operacao", "SemParar")
      .set("devedor_cod", devedor_cod)
      .set("parcela_cod", parcela_cod)
      .set("origin", "portal");
    return this.http.post(this.urlEnviaSms, params, this.httpOptions).pipe(
      retry(this.retry),
      catchError(() => {
        return EMPTY;
      }),
      shareReplay()
    );
  }

  gravaAcordo(): Observable<any> {
    this.loading = true;
    (<any>window).CPF_TRACE.S3 = "gravaAcordo";

    const params = new HttpParams()
      .set("dtpagto", this.dataPagamento)
      .set("cpf", this.cpfCnpj)
      .set("pctdesconto", this.descontoEscolhido.slice(0, -1))
      .set("valdesconto", "0")
      .set("credor", "1")
      .set("regis", this.devedor.data.cliente[0].Regis)
      .set("descdespesa", "N")
      .set("deschonorarios", "N")
      .set("valoradicional", "0")
      .set("produt", "SEMPARAR")
      .set("operesp", "")
      .set("contr", this.divida.data.DIVIDA.PREST[0].CONTRATO)
      .set("vencto", this.divida.data.DIVIDA.PREST[0].VENCIMENTO)
      .set("numprest", JSON.stringify(this.prest))
      .set("produto", "SEMPARAR")
      .set("contrato", this.divida.data.DIVIDA.PREST[0].CONTRATO)
      .set("dtentrada", this.dataPagamento)
      .set("qtdparc", String(this.quantidadeParcelas - 1))
      .set("valentrada", "0")
      .set("descpercentual", "S")
      .set("desconto", this.descontoEscolhido.slice(0, -1))
      .set("renegociacao", "N")
      .set("propostaacordo", "N")
      .set("datasmensais", "S")
      .set("parcelasiguais", "S")
      .set("todasnrc", "N")
      .set("tipoenviobol", "")
      .set("formapagtoentrada", "B")
      .set("origin", "portal");

    return this.http.post(this.urlGravaAcordo, params, this.httpOptions).pipe(
      retry(this.retry),
      catchError(() => {
        return EMPTY;
      }),
      shareReplay()
    );
  }

  enviaBoletoEmail(
    contrato: string,
    valor: string,
    vencimento: string,
    linha: string,
    codigo: string,
    parcela_num: number
  ): Observable<any> {
    const devedor_cod = (<any>window).CPF_DEBTOR_COD;
    const parcela_cod = (<any>window).CPF_PARCEL_COD;

    const set_parcela = "e" + parcela_num;

    if (Object.values((<any>window).CPF_TRACE.S4).indexOf(set_parcela) <= -1) {
      (<any>window).CPF_TRACE.S4.push(set_parcela);
    }

    linha = linha.trim();
    linha = linha.replace(/\s\s\s/g, " ");
    linha = linha.replace(/\s\s/g, " ");

    const params = new HttpParams()
      .set("cliente", this.devedor.data.cliente[0].Nome.toLocaleUpperCase())
      .set("cpf", this.cpfCnpj)
      .set("contrato", contrato)
      .set("valor", valor)
      .set("vencimento", vencimento)
      .set("codigobarra", codigo)
      .set("linhadigitavel", linha)
      .set("email", this.email)
      .set("operacao", "SemParar")
      .set("devedor_cod", devedor_cod)
      .set("parcela_cod", parcela_cod)
      .set("origin", "portal");

    return this.http.post(this.urlBoletoEmail, params, this.httpOptions).pipe(
      retry(this.retry),
      catchError(() => {
        return EMPTY;
      }),
      shareReplay()
    );
  }

  temAcordo(cpfCnpj: string): Observable<any> {
    const cpfCnpjParam = new HttpParams()
      .set("cpf", cpfCnpj)
      .set("origin", "portal")
      .set("teste", "sim");
    return this.http
      .post<any>(this.urlTemAcordo, cpfCnpjParam, this.httpOptions)
      .pipe(
        retry(this.retry),
        catchError(() => {
          return EMPTY;
        }),
        shareReplay()
      );
  }

  temDesconto(cpfCnpj: string, dias: string): Observable<any> {
    let valorOriginal = 0.0;
    this.divida.data.DIVIDA.PREST.forEach((pres) => {
      valorOriginal += +pres.VALPRINC.replace(",", ".");
    });

    if (Number(dias) > 1080) {
      dias = "1080";
    }

    const cpfCnpjParam = new HttpParams()
      .set("cpf", cpfCnpj)
      .set("dias_atraso", dias)
      .set("dia_previsto_pagamento", "")
      .set("valor_original", String(valorOriginal))
      .set("origin", "portal");
    return this.http
      .post(this.urlTemDesconto, cpfCnpjParam, this.httpOptions)
      .pipe(
        retry(this.retry),
        catchError(() => {
          return EMPTY;
        }),
        shareReplay()
      );
  }

  doisDigitosDecimais(num: string) {
    num = num.replace(",", ".");
    if (num.indexOf(".") === num.length - 2) return num + "0";
    if (num.indexOf(".") === -1) return num + ".00";
    return num;
  }

  validarESalvarEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (typeof email != "undefined" && re.test(email.toLowerCase())) {
      this.email = email;
      return true;
    }
    return false;
  }

  validarESalvarTelefone(telefone) {
    const re = /\(\d{2}\)\s\d{4,5}-?\d{4}/;
    if (typeof telefone != "undefined" && re.test(telefone)) {
      this.telefone = telefone.replace(/[`\-()_.\{\}\[\] \\\/]/gi, "");
      return true;
    }
    return false;
  }
}

export class Divida {
  data: {
    CREDOR?: string;
    DIVIDA: {
      PREST: Array<{
        CONTRATO?: string;
        CREDOR?: string;
        NUMPREST?: string;
        PRODUTO: string;
        VALPRINC: string;
        VENCIMENTO: string;
      }>;
    };
  };
}

export class Prest {
  produt: string;
  contr: string;
  vencto: string;
  numprest: string;
}

export class Devedor {
  data: {
    condigo?: string;
    status?: {
      condigo: string;
    };
    cliente: Array<{
      Regis: string;
      Contrato: string;
      Nome: string;
    }>;
  };
}

export class Parcela {
  TAXAADM: string;
  MULTA: string;
  JUROS: string;
  PRINCIPAL: string;
  PAGO: string;
  VENCIMENTO: string;
}

export class Boleto {
  data: {
    result: {
      DADOBOLETO: {
        AGENCIACONTA: string;
        CODIGOBARRA: string;
        DESINSTR2: string;
        NOMCLIEN: string;
        VALPAGO: string;
        LINHADIGITAVEL: string;
        DATVENCI: string;
        INSTRUCAO: string;
      };
    };
  };
}
