import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiRestService, Boleto } from '../api-rest.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-fiz-pagamento',
  templateUrl: './fiz-pagamento.component.html',
  styleUrls: ['./fiz-pagamento.component.css']
})
export class FizPagamentoComponent implements OnInit {

  public maxDate = new Date();
  public loader: boolean;
  public dataNegociacao: string;
  public valorNegociacao: string;
  public contratoNegociacao: string;
  public prest = [];  
  
  constructor(public apiRestService: ApiRestService, private localeService: BsLocaleService, private cd: ChangeDetectorRef) { 
    this.localeService.use('pt-br');    
  }

  ngOnInit() {
  
    if (this.apiRestService.segundaVia) {
      this.loader = true;
       
      this.apiRestService.getBoletoAcordo(this.apiRestService.dataPagamento).subscribe(res => {
          this.loader = false;
          if (res.data && res.data.result && res.data.result.DADOBOLETO) {
            this.dataNegociacao = res.data.result.DADOBOLETO.DATVENCI;
            this.valorNegociacao = res.data.result.DADOBOLETO.VALPAGO;
            this.contratoNegociacao = res.data.result.DADOBOLETO.INSTRUCAO;
          }
      });                
    }

    else {
      this.prest = this.apiRestService.divida.data.DIVIDA.PREST;
    }
    
  }
}