import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { DatePipe } from "@angular/common";
import { ApiRestService, Parcela, Devedor } from "../api-rest.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-negocie-online",
  templateUrl: "./negocie-online.component.html",
  styleUrls: ["./negocie-online.component.css"],
})
export class NegocieOnlineComponent implements OnInit {
  minDate: Date = new Date();
  maxDate: Date = new Date();

  constructor(
    private localeService: BsLocaleService,
    public apiRestService: ApiRestService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,

  ) {
    // Configurando bootstrap datepicker para portugês
    this.localeService.use("pt-Br");

    this.datepipe = new DatePipe("pt");
    this.maxDate.setDate(
      this.minDate.getDate() + this.apiRestService.PRAZOPAGTO + 1
    );

    for (
      let d = new Date();
      d.getTime() <= this.maxDate.getTime();
      d.setDate(d.getDate() + 1)
    ) {
      if (d.getDay() !== 0 && d.getDay() !== 6) {
        this.maxDate.setDate(
          this.minDate.getDate() + this.apiRestService.PRAZOPAGTO + 2
        );
      }
    }
  }

  public numeroDeParcelas = 1;
  public hasParcelamento = false;
  public descontos = [{
    'parcelas' : 1,
    'desconto' : "0%"
  }];

  public datepipe: DatePipe;

  public hoje: Date = new Date();

  public prazoFinalizacao: boolean;

  public dataPagamentoDate: Date = this.hoje;

  public loader: boolean;

  public valorParcela: string;

  public dadosDivida;

  public valorAVista: Parcela;

  public devedor: Devedor;

  // DADOS DA PARCELA

  public valorTotal: number;

  public valorDivida: number;

  public valorJuros: number;

  public valorTaxaAdm: number;

  public valorMulta: number;

  public valorTotalLabel: string;

  public valorParceladoLabel: string;

  public comDesconto: string;

  ngOnInit() {
    this.loader = true;
    this.devedor = this.apiRestService.devedor;
    this.dadosDivida = this.apiRestService.divida.data.DIVIDA.PREST[0];
    this.dadosDivida.quantidadeParcelas =
      this.apiRestService.divida.data.DIVIDA.PREST.length;
    if (this.apiRestService.segundaVia) {
      this.prazoFinalizacao = true;
    } else {
      this.apiRestService.divida.data.DIVIDA.PREST.forEach((divida) => {
        this.apiRestService.prest.push({
          produt: divida.PRODUTO,
          contr: divida.CONTRATO,
          vencto: divida.VENCIMENTO,
          numprest: divida.NUMPREST,
        });
      });

      this.dataPagamentoDate = new Date();
      if(this.dataPagamentoDate.getDay() == 6){
        this.dataPagamentoDate.setDate(this.dataPagamentoDate.getDate() + 2);
      }else if(this.dataPagamentoDate.getDay() == 0){
        this.dataPagamentoDate.setDate(this.dataPagamentoDate.getDate() + 1);
      }

      this.apiRestService.dataPagamento = this.datepipe.transform(
        this.dataPagamentoDate,
        "y-MM-dd"
      );

      if (this.apiRestService.PARC.length > 1) {
        this.hasParcelamento = true;
        this.criarParcelamento();
      }

      this.showProposta();
    }
  }

  alterarDataPagamento() {
    const prazo =
      Math.ceil(
        (this.dataPagamentoDate.getTime() - this.hoje.getTime()) / 86400000
      ) + 1;
    this.descontos = this.apiRestService.descontos.filter(
      (desconto) => desconto.qtde_dias === prazo
    );
  }

  showProposta() {
    this.apiRestService.dataPagamento = this.datepipe.transform(
      this.dataPagamentoDate,
      "y-MM-dd"
    );
    this.loader = true;

    let prazo =
      Math.ceil(
        (this.dataPagamentoDate.getTime() - this.hoje.getTime()) / 86400000
      ) + 1;

    if (!this.apiRestService.PRAZOS.includes(String(prazo))) {
      prazo = this.apiRestService.PRAZOS.reduce(
        (i, j) => (j <= prazo ? Math.max(i, j) : i),
        0
      );
    }
    if(this.descontos.length){
      this.apiRestService.descontoEscolhido =
      this.descontos.find(
        (desconto) => desconto.parcelas == this.numeroDeParcelas
      ).desconto;
    }

    this.apiRestService
      .getOpcoesPagamento(this.numeroDeParcelas, this.apiRestService.descontoEscolhido)
      .subscribe((res: any) => {
        this.loader = false;
        if (res.data && res.data.RETORNO && res.data.RETORNO.CODIGO === "00") {
          this.loader = false;
          this.valorAVista = res.data.PARCELAS;
          this.valorTotal = res.data.RETORNO.VALORTOTAL;
          this.valorDivida = res.data.RETORNO.PRINCIPAL;
          this.valorMulta = res.data.RETORNO.MULTA;
          this.valorJuros = res.data.RETORNO.JUROS;
          this.valorTaxaAdm = res.data.RETORNO.TXADM;
          this.valorTotalLabel = '';
         
          if(this.apiRestService.descontoEscolhido !== '0%'){
            this.comDesconto = ' com desconto'
          }else{
            this.comDesconto = '';
          }
          if(res.data.PARCELAS.length > 1){
            this.valorTotalLabel = ' parcelado';
            this.valorParceladoLabel = 'Em ' + res.data.PARCELAS.length + 'x de R$ ' + res.data.PARCELAS[0].PAGO
          }
        }else{
          this.numeroDeParcelas = 1;
          this.toastr.error('Numero de parcelas não permitido.');
          this.showProposta();

        }
      });
  }

  voltarParcela() {
    this.prazoFinalizacao = false;
  }

  setAVista() {
    this.apiRestService.quantidadeParcelas = 1;
    if (this.valorParcela) this.apiRestService.valorParcela = this.valorParcela;
  }

  verProposta() {
    let valorLabel = '';
    if(this.valorTotalLabel !== ' parcelado'){
      valorLabel = 'R$' + this.valorTotal + this.valorTotalLabel;
    }else{
      valorLabel = this.valorParceladoLabel ;
    }
    this.prazoFinalizacao = true;
    this.apiRestService.dadosPagamento = {
      valorTotalLabel : 'Valor total' + this.valorTotalLabel + this.comDesconto,
      dataPagamento: this.apiRestService.dataPagamento,
      valorLabel: valorLabel,
      valorAVista: this.valorAVista,
      valorTotal: this.valorTotal,
      valorDivida: this.valorDivida,
      valorMulta: this.valorMulta,
      valorJuros: this.valorJuros,
      valorTaxaAdm: this.valorTaxaAdm,
    };
  }

  criarParcelamento() {
    this.descontos = []
    const prazo =
      Math.ceil(
        (this.dataPagamentoDate.getTime() - this.hoje.getTime()) / 86400000
      ) + 1;
        
    const descontosDisponiveis = this.apiRestService.descontos.filter(
      (desconto) => desconto.qtde_dias === String(prazo)
    );
    if(descontosDisponiveis.length > 0){
      this.descontos.push({
        'parcelas' : 1,
        'desconto' : descontosDisponiveis[0].desconto_enviar
      })
      for(let parcelas = 1; parcelas < descontosDisponiveis[1].qtde_parcelas;parcelas++){
        this.descontos.push({
          'parcelas' : parcelas + 1,
          'desconto' : descontosDisponiveis[1].desconto_enviar
        });
      }
    }else{
      this.descontos.push({
        'parcelas' : 1,
        'desconto' : "0%"
      })
    }
    this.numeroDeParcelas = 1;
    this.showProposta();
  }

  keyDatepicker(event: any) {
    this.dataPagamentoDate = null;
    this.cd.detectChanges();
    event.preventDefault();
  }


  changeDate() {
    if (this.hasParcelamento) this.criarParcelamento();
    else this.showProposta();
  }
}
